export const completedGames: string[] = [
	'A Plague Tale: Innocence',
	'A Way Out',
	"Assassin's Creed",
	"Assassin's Creed II",
	"Assassin's Creed III",
	"Assassin's Creed III: Liberation",
	"Assassin's Creed IV: Black Flag",
	"Assassin's Creed IV: Black Flag - Freedom Cry",
	"Assassin's Creed: Brotherhood",
	"Assassin's Creed: Odyssey",
	"Assassin's Creed: Origins",
	"Assassin's Creed: Revelations",
	"Assassin's Creed: Rogue",
	"Assassin's Creed: Syndicate",
	"Assassin's Creed: Unity",
	'Batman: Arkham Asylum',
	'Batman: Arkham City',
	'Batman: Arkham Origins',
	'Battlefield 1',
	'Battlefield 3',
	'Battlefield 4',
	'Battlefield V',
	'Brothers: A Tale of Two Sons',
	'Call of Duty',
	'Call of Duty: Black Ops',
	'Call of Duty: Black Ops II',
	'Call of Duty: Black Ops III',
	'Call of Duty: Ghosts',
	'Call of Duty: Modern Warfare',
	'Call of Duty: Modern Warfare 2',
	'Call of Duty: Modern Warfare 3',
	'Call of Duty: Modern Warfare (2019)',
	'Call of Duty: Modern Warfare II (2022)',
	'Call of Duty: WWII',
	'Celeste',
	'Detroit: Become Human',
	'DmC: Devil May Cry',
	'DOOM',
	'Far Cry 3',
	'Far Cry 4',
	'Farm Frenzy 2',
	'Firewatch',
	'For Honor',
	'Freedom Fighters',
	'Ghostrunner',
	'Ghost Recon: Future Soldier',
	'God of War',
	'Gone Home',
	'Grand Theft Auto V',
	'Grand Theft Auto: Vice City',
	'Hades',
	"Hellblade: Senua's Sacrifice",
	'Heroes of Hellas',
	'Heroes of Hellas 2 - Olympia',
	'Heroes of Hellas 3 - Athens',
	'Hitman',
	'Hitman 2',
	'Hitman 3',
	'Hitman: Absolution',
	'Horizon Zero Dawn',
	'Inside',
	'It Takes Two',
	'Journey',
	'Kena: Bridge of Spirits',
	'Life Is Strange',
	'Life Is Strange: Before the Storm',
	'Life Is Strange: True Colors',
	'Limbo',
	'Little Nightmares',
	'Little Nightmares II',
	"Marvel's Avengers",
	"Marvel's Spider-Man",
	"Marvel's Spider-Man: Miles Morales",
	"Mirror's Edge",
	"Mirror's Edge Catalyst",
	'Need for Speed: Most Wanted',
	'Need for Speed: Underground 2',
	'Neon White',
	'Ori and the Blind Forest',
	'Ori and the Will of the Wisps',
	'Portal',
	'Portal 2',
	'Prince of Persia: The Forgotten Sands',
	'Prince of Persia: The Sands of Time',
	'Prince of Persia: The Two Thrones',
	'Prince of Persia: Warrior Within',
	'Quantum Break',
	'Raji: An Ancient Epic',
	'RiME',
	'Rise of the Tomb Raider',
	'Roads of Rome II',
	'Scooby-Doo: Phantom of the Knight',
	'Sekiro: Shadows Die Twice',
	'Shadow of the Tomb Raider',
	'Spec Ops: The Line',
	'Stray',
	'The Last Of Us: Part 1',
	'The Stanley Parable',
	'Titanfall 2',
	'Tomb Raider',
	'Total Overdose',
	"Uncharted 4: A Thief's End",
	'Uncharted: The Lost Legacy',
	'Urban Freestyle Soccer',
	'Virtua Cop 2',
	'What Remains of Edith Finch'
].sort();

export const unfinishedGames: string[] = [
	'Among Us',
	'Apex Legends',
	'Ashes Cricket 2009',
	"Assassin's Creed: Valhalla",
	'Baba Is You',
	'Claw',
	'Counter-Strike 1.6',
	'Counter-Strike: Condition Zero',
	'Counter-Strike: Global Offensive',
	'Crazy Taxi',
	'Cricket 07',
	'Cricket 2000',
	'Dangerous Dave',
	'Far Cry 2',
	'FIFA',
	'Forza Horizon 4',
	'Grand Theft Auto: San Andreas',
	'Grand Theft Auto III',
	'Metal Gear Rising: Revengeance',
	'Midtown Madness',
	'Need for Speed: Hot Pursuit',
	'Need for Speed: Porsche Unleashed',
	'Need for Speed: Underground',
	'Prince of Persia',
	"Project I.G.I.: I'm Going In",
	'Roadrash',
	'Rugby 08',
	'Sifu',
	'Valorant',
	'We Happy Few',
	'WWE SmackDown vs. Raw'
].sort();
